@import url('https://fonts.googleapis.com/css?family=Roboto');

html, #root {
  height: 100%;
}
body {
  font-family: Roboto;
  width: calc( 100% );
  max-width: calc( 100vw - 1em );
  height: 100%;
}

h1 {

  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 114%;

  text-align: center;
  letter-spacing: -0.02em;

  color: #000B32;
}

h3 {
  font-weight: bold;
  text-align: center;
  color: #000B32;
}

.centered_text {
  text-align: center;
}

.welcome-text {
  text-align: center;
  margin-top: 18px;
}

.nl-content {
  font-size: 17px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #39456C;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
}

span.strong {
  font-weight: bold;
}

.small-text {
  font-size: 10px;
  line-height: 154%;
}

.medium-text {
  font-size: 13px;
  line-height: 20px;
}
.grayed-text {
  color: #8A93B4;
}
div.wide {
  width: 100%;
}
div.head {
  position: relative;
}

div.step-container {
  position: relative;
  width: 452px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

div.step-container div.step-line {
  display: flex;
  height: 4px;
  width: 100%;
  border-radius: 0px;
  background: #E6EAF4;
}

div.step-container div.step-line.active {
  background: linear-gradient(270deg, #1462D8 1.09%, #2DB9E5 100%);
}

div.step-container div.step-point {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9px;
  height: 9px;
  background: #1462D8;
  box-shadow: 0px 1px 14px rgba(4, 31, 114, 0.1);
  box-sizing: border-box;
  border: 2px solid #1462D8;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;

}

div.step-container div.step-sub-text {
  font-size: 16px;
  line-height: 21px;
  width: 121px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #8A93B4;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

div.step-container div.step-sub-text.text-left {
  text-align: left;
}

div.step-container div.step-sub-text.text-right {
  text-align: right;
}

div.step-container div.step-sub-text.active {
  font-weight: 600;
  color: #1462D8;
}

div.App {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 14px 38px rgba(4, 31, 114, 0.06);
  border-radius: 14px;
  width: 701px;
  margin: 0 auto;
  padding: 54px 91px 54px 91px;
  margin-top: 40px;
  margin-bottom: 40px;
}

div.nl-header {
  position: absolute;
  width: 100%;
  height: 263px;
  left: 0px;
  top: 0px;
  background: linear-gradient(0deg, #F2F4FA 0%, #F4F6FB 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

div.nl-body {
  flex: 1 0 auto;
}

div.nl-footer {

  width: 100%;
  height: 72px;
  margin-top: 5px;
  /* BG/Solid */
  background: #F8F9FC;
  flex-shrink: 0;
  align-self: flex-end;
}

div.nl-footer div {
  padding-right: 34px;
}

div.nl-footer a {
  text-decoration: none;
  font-size: 15px;
  line-height: 158%;
  letter-spacing: -0.01em;
  color: #39456C;
}

div.flex-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

div.nl-welcome-icon-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  padding-left: 25px;
}

div.nl-welcome-icon-block{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  font-weight: 600;
}

div.flex-centered {
  justify-content: center !important;
}


div.flex-row-container div.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.flex-center-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

div.flex-row-container-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
}

.nl-button {
  justify-content: center;
  align-items: center;
  padding: 12px 36px 14px;
  background: #1462D8;
  border: solid #1462D8 1px;
  border-radius: 2px;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;
  gap: 10px;
}

.nl-button-welcome-start{
  line-height: 15px;
}
.gap-21{
  gap: 21px;
}
.nl-button[disabled] {
  background: #CED3E2;
  border: solid #CED3E2 1px;
}

.nl-button-white {
  justify-content: center;
  align-items: center;
  padding: 12px 36px 14px;
  background: #FFFFFF;
  border: solid #FFFFFF 1px;
  border-radius: 2px;
  color: #1462D8;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}
.nl-button-white-bordered {
  justify-content: center;
  align-items: center;
  padding: 12px 36px 14px;
  background: #FFFFFF;
  border: 1px solid #1462D8;
  border-radius: 2px;
  color: #1462D8;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}
.nl-button-white-bordered[disabled] {
  background: #CED3E2;
}

.nl-button-tolls-small {
  background: #FFFFFF;
  display: inline-block;
  border: 1px solid #E6EAF4;
  box-sizing: border-box;
  border-radius: 2px;
  color: #8A93B4;
  text-align: center;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}


div.top-margin {
  margin-top: 40px;
}
div.top-margin-20 {
  margin-top: 20px;
}

div.bottom-margin {
  margin-bottom: 40px;
}

div.consent-content {
  background: #F8F9FC;
  border-radius: 12px;
  padding: 19px 22px 19px 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.consent-row {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

}
dov.sub_consent-row {
  margin-top: 12px;
}

div.consent-row div.consent-description-col {
  padding-left: 5px
}

div.tools {
  position: absolute;
  right: 0px;
  float: right;
}

div.flex-tools {
  text-align: right;
  white-space: nowrap;
}

.nl-link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1462D8;
  cursor: pointer;
  text-decoration: none;
}
.nl-link-black{
  cursor: pointer;
  color: #000B32;
;
}

.uppercase {
  text-transform: uppercase;
}

div.consent-row div.consent-title {
  font-size: 15px;
  line-height: 158%;
  letter-spacing: -0.01em;
  color: #000B32;
}

div.consent-row div.consent-text {
  font-size: 13px;
  line-height: 156%;
  color: #39456C;
  text-indent: 10px;
}

div.consent-text div.sub-consent-title {
  font-weight: bold;
}

div.consent-text div.partner-consent-sub-row{
  margin-bottom: 5px;
}

div.consent-text div.sub-consent-text {
  text-indent: 10px;
}

div.consent-row input[type=checkbox] {
  width: 20px;
  height: 20px;
  background: #1462D8;
  border-radius: 2px;
}

div.faq {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

div.faq-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

div.faq-menu .faq-menu-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 155px;
  min-height: 42px;
  padding: 18px 5px 18px 22px;
  justify-content: center;
}

div.faq-menu-item a {
  cursor: pointer;
}

div.faq-menu-item.active {
  background: #F8F9FC;
  box-shadow: inset 2px 0px 0px #000825;
  border-radius: 0px 8px 8px 0px;
}

div.faq-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-left: 28px;
}

div.faq-content div.faq-question-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  background: #F8F9FC;
  border-radius: 8px;
  padding: 19px 22px 18px 26px;
  justify-content: space-between;
  margin-bottom: 8px;
}

div.faq-question-row div.faq-question {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #000B32;
}

div.faq-question-row div.faq-answer {
  margin-top: 12px;
  font-size: 15px;
  line-height: 158%;
}

div.faq-question-row a {
  cursor: pointer;
}

.tools-icon {
  color: #1462D8;
}

div.view-warning {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: baseline;
  padding: 8px 0px 10px 14px;
  background: #E6EAF4;
  border-radius: 12px 12px 12px 0px;
}

div.loan-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
}

div.loan-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 16px;
}

div.loan-heading div.loan-heading-col {
  width: 50%;
  background: #F8F9FC;
  border-radius: 12px;
  padding: 12px 22px 12px 22px;
}

div.loan-heading div.loan-heading-col:first-child {
  margin-right: 27px;
}

div.loan-heading-col div.heading-col-label {
  font-size: 15px;
  line-height: 158%;
  letter-spacing: -0.01em;
  color: #8A93B4;
}

div.loan-heading-col div.heading-col-text {
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #000B32;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
div.loan-requisites {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  background: #F8F9FC;
  border-radius: 12px;
  padding-left: 22px;
}

div.loan-requisite-row {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

div.loan-requisite-row div.loan-requisite-col {
  width: 50%;
  padding-bottom: 12px;
}

div.loan-requisite-row div.loan-requisite-title {
  font-size: 15px;
  line-height: 158%;
  letter-spacing: -0.01em;
  color: #8A93B4;
}

div.loan-requisite-row div.loan-requisite-data {
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #000B32;
}

div.error-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
}

div.error-title {
  font-size: 36px;
  line-height: 114%;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000B32;
}

div.error-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #39456C;
  margin-top: 18px;
}

div.clear-data-title {
  font-size: 36px;
  line-height: 114%;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000B32;
}

div.clear-data-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #39456C;
  margin-top: 32px;
}

div.loader-title {
  font-size: 36px;
  line-height: 114%;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000B32;
}

div.loader-body {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: baseline;
  justify-content: center;
  padding-top: 54px;
}

div.loader-text {
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #39456C;
  padding-top: 18px;
}

div.loader-small-text {
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #39456C;
  padding-top: 18px;
}

div.feedback-container {
  background: #F8F9FC;
  border-radius: 12px;
  padding: 22px;
  margin-top: 72px;
}

div.feedback-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #000B32;
}

div.feedback-message {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 156%;
  color: #39456C;
}

div.feedback-links {
  padding-top: 18px;
  padding-bottom: 30px;
}

div.rate-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

div.rate-box div {
  cursor: pointer;
  color: #1462D8;
}

input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E6EAF4;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #8A93B4;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left !important;
}
textarea {
  border: 1px solid #E3E5EB;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #8A93B4;
}
.input-error {
  border: 1px solid #ED1C35!important;
}

.input:focus {
  border: 1px solid #1462D8 !important;
}

.nl-input {
  width: 100% !important;
  border: 1px solid #E6EAF4!important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-size: 17px !important;
  line-height: 150% !important;
  letter-spacing: -0.01em !important;
  color: #8A93B4 !important;
  height: 55px !important;
}

.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon-right > i {
  right: 12px;
  color: #8A93B4;
}

.input-icon-right > input {
  padding-left: 15px;
  padding-right: 35px;
  text-align: right;
}

div.distribution-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 114%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000B32;
}

div.distribution-text {
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 37px;
  color: #39456C;
}

div.decline-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 114%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000B32;
}

div.decline-text {
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 50px;
  margin-bottom: 51px;
  color: #39456C;
}
.top-margin-50 {
  margin-top: 50px;
}
select.nl-select {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E6EAF4;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #8A93B4;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
}

.lang-selector {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #E6EAF4;
  border-radius: 6px;
  font: inherit;
  line-height: 150%;
  padding: 0.5em 2em 0.5em 1em;
  color: #8A93B4;
  display: inline-block;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.minimal {
  background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
          calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 2.5em) 0.5em;
  background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  box-shadow: 0px 0px 0px 2px #1462D8 !important;
}
.nl-phone-container:focus {
  border: 2px solid #1462D8 !important;
}
.form-control:focus {
  box-shadow: 0px 0px 0px 2px #1462D8 !important;
}
a.nl-simple-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #39456C;
  cursor: pointer;
}

div.lang-selector-container{
  padding: 10px 10px 10px 10px;
  float: right;
}
.nl-logo {
  padding: 10px 10px 10px 10px;
}
.top-logo {
  padding-left: 16px;
}
.logo-block{
  margin-bottom: 30px;
}

.nl-modal {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 14px 38px rgba(4, 31, 114, 0.06);
  border-radius: 14px;
}

.nl-modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;

}

.nl-modal-body {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  padding-top: 18px;
  color: #39456C;
  margin: 10px 0px;
}

.nl-modal-content{
  padding: 58px 91px 58px 91px;
}

.nl-modal-footer {
  padding-top: 18px;
}

.blue-mark-text {
  color: #1462D8;
  font-weight: bold;
}
.blue-text {
  color: #1462D8;
}

.suggestion-hint {
  padding: 8px 16px 10px 14px;
  margin-top: 25px;
  background: #FFFFFF;
  border: 1px solid #E6EAF4;
  box-sizing: border-box;
  border-radius: 12px;
  color: #1F3075;
}
.consent-hint {

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 14px;
  gap: 6px;

  width: 519px;

  background: #FFFFFF;

  border: 1px solid #E6EAF4;
  border-radius: 0px 12px 12px 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.single-suggestion-container{
  margin-top: 8px;
  padding: 22px 22px 22px 22px;
  background: #F8F9FC;
  border-radius: 12px;
}

.suggestion-line-container {

}

.suggestion-line-button {
  display: flex;
  height: 5px;
  width: 100%;
  border-radius: 1px;
  background: #CED3E2;
  cursor: pointer;
}

.suggestion-line-button.filled {
  background: #09BC59;
}
.suggestion-line-button.active {
  border: 0.5px solid #1462D8;
  border-radius: 1px;
}
.suggestion-line-button.active:not(.filled) {
  background: #FFFFFF;
}

.suggestion-line-button.active {
  border: 0.5px solid #1462D8;
  border-radius: 1px;
}

.suggestion-line-button:not(:first-child) {
  margin-left: 4px;
}

.suggestion-title {
  margin-top: 30px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #39456C;
}

.nl-select-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000B32;
  margin-bottom: 14px;
}

.nl-error-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142%;
  text-align: center;
  color: #FE3156;
}

.nl-error-text-block{
  margin-top: 20px;
}

.suggestion-tools-container{
  margin-top: 20px;
}

.div-suggestion-tools-small{
  width: 30px;
  height: 30px;
}

.loan-review-term-amount {
  background: #F8F9FC;
  border-radius: 12px;
  padding: 22px 22px 22px 22px;
  margin-top: 30px;
}

.nl-slider {
  color: #1462D8 !important;
  margin-top: 10px;
}

div.loan-suggestions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  height: 325px;
  background: #F8F9FC;
  border-radius: 12px;
  padding: 12px 12px 12px 12px;
  overflow-y: auto;
}

div.suggestion-row {
  margin-top: 5px;
  padding: 6px 12px 6px 12px;
}
div.suggestion-row:hover {
  background: rgba(230, 234, 244, 0.5);
  border-radius: 12px;
}
div.suggestion-row div.suggestion-row-title {
  font-size: 15px;
  line-height: 158%;
  letter-spacing: -0.01em;
  color: #8A93B4;
}

div.suggestion-row div.suggestion-row-data {
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #000B32;
}

.nl-veiled-button {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 156%;
  text-align: right;
  padding-left: 7px;
  padding-right: 7px;
}

.nl-tooltip {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 156%;
  text-align: center;
  color: #8A93B4;
}

.nl-header-tools-button {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #1462D8;
  text-decoration: none;
  cursor: pointer;
}

.header-tools{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
}

div.buyers-list {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

div.buyers-list-col {
  width: 50%;
  padding-bottom: 12px;
}

div.buyers-list-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
div.buyers-list-name {
  margin-left: 18px;
}
a.partner-link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}